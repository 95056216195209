import React from "react";
import building from "../images/building.png";
import space from "../images/space.jpg";
import chair from "../images/chair.jpg";
import desk from "../images/desk.jpg";
import HubspotForm from 'react-hubspot-form'
import "../App.css";

function Home() {
	return (
		<div>
			<div id="home" style={{ backgroundColor: "#faf8f2" }} className="shadow">
				<div
					className="container mx-auto px-4 py-8"
					style={{
						boxShadow:
							"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
					}}>
					<div style={{ position: "relative", textAlign: "center" }}>
						<img
							src={building}
							alt="V-Work"
							style={{ width: "100%", borderRadius: "25px" }}
						/>
						<div
							style={{
								position: "absolute",
								top: "150px",
								width: "500px",
								height: "120px",
								backgroundColor: "#cfbfb8",
								borderRadius: "0px 25px",
							}}>
							<p className="font-serif text-5xl" style={{ marginTop: "16px" }}>
								{" "}
								V - WORK{" "}
							</p>
							<p className="font-extralight text-2xl"> The Hybrid Office</p>
						</div>
					</div>
					<div className="mdl-grid">
						<div
							className="mdl-cell demo-card-square mdl-card mdl-shadow--2dp"
							style={{ borderRadius: "25px" }}>
							<img src={space} alt="V Works" />
						</div>
						<div
							className="mdl-cell demo-card-square mdl-card mdl-shadow--2dp"
							style={{ borderRadius: "25px" }}>
							<img style={{ height: "315px" }} src={chair} alt="V Works" />
						</div>
						<div
							className="mdl-cell demo-card-square mdl-card mdl-shadow--2dp"
							style={{ borderRadius: "25px" }}>
							<img style={{ height: "315px" }} src={desk} alt="V Works" />
						</div>
					</div>
					<div className="mdl-grid">
						<h2 className="font-serif text-4xl">ABOUT V-WORK</h2>
						<p style={{ textAlign: "left" }}>
							Krishe Sapphire - The building is a <strong>Grade A</strong>{" "}
							modern office building designed in a way to provide design
							flexibility for Corporates. The building is a{" "}
							<strong>Green</strong> building with its efficient water recycling
							systems, energy saving electrical systems, highly efficient air
							conditioning systems with complete power back up, eco friendly
							building material to ensure high quality, long life and low
							maintenance. The building is located in the <strong>prime</strong>{" "}
							location of Madhapur with accessibility to metro rail and public
							transportation. Located at{" "}
							<strong>Durgam Cheruvu Metro station</strong>, has excellent
							connectivity to city centre at Ameerpet Metro hub. With Metro rail
							being extended to the Airport, offers direct connectivity to
							Airport
						</p>
					</div>

					<div className="mdl-grid">
						{/*<div*/}
						{/*	className="mdl-cell mdl-cell--8-col-desktop"*/}
						{/*	style={{ marginLeft: "0px" }}>*/}
						{/*	<h2 className="font-serif text-4xl" style={{ textAlign: "left" }}>*/}
						{/*		PROPERTY SPECIFICATION*/}
						{/*	</h2>*/}
						{/*	<div className="mdl-grid">*/}
						{/*		<span*/}
						{/*			className="material-symbols-outlined"*/}
						{/*			style={{ fontSize: "55px", margin: "24px" }}>*/}
						{/*			adf_scanner*/}
						{/*		</span>*/}
						{/*		<span*/}
						{/*			className="material-symbols-outlined"*/}
						{/*			style={{ fontSize: "55px", margin: "24px" }}>*/}
						{/*			apartment*/}
						{/*		</span>*/}
						{/*		<span*/}
						{/*			className="material-symbols-outlined"*/}
						{/*			style={{ fontSize: "55px", margin: "24px" }}>*/}
						{/*			meeting_room*/}
						{/*		</span>*/}
						{/*		<span*/}
						{/*			className="material-symbols-outlined"*/}
						{/*			style={{ fontSize: "55px", margin: "24px" }}>*/}
						{/*			print_connect*/}
						{/*		</span>*/}
						{/*	</div>*/}
						{/*</div>*/}
						<div
							className="mdl-card mdl-cell--10-col-desktop"
							style={{
								backgroundColor: "#cfbfb8",
								textAlign: "center",
								borderRadius: "25px",
								margin: "auto",
							}}>
							<h2 className="font-serif text-4xl">CONTACT US</h2>
							<p>
								<a href="https://goo.gl/maps/YmEyN9vejFnJpNDB7"> V-Work Hybrid spaces, 7th Floor <br />
								Sy.No.88, Krishe Sapphire, Hi-techcity Main Road, Madhapur,
								Hyderabad - 500081 <br />
							    </a>
								<br />
								<a href="tel:+91 9885457606">
									Call us at: +91 98854 57606
								</a>
								<br />
								<a href="mailto:sales@vwork-hybrid.com">
									sales@vwork-hybrid.com
								</a>
								<br />
								<a href="mailto:info@vwork-hybrid.com">info@vwork-hybrid.com</a>
							</p>
							<HubspotForm
								portalId='43751471'
								formId='50e667f2-a30b-495b-9c4d-15e27111dbfc'
								onSubmit={() => console.log('Submit!')}
								onReady={(form) => console.log('Form ready!')}
								loading={<div>Loading...</div>}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Home;
